<template>
  <Header></Header>
  <router-view/>
  <Foot></Foot>
</template>

<script>
import Header from '@/components/Header.vue'
import Foot from '@/components/Foot.vue'
export default {
  components:{
    Header,
    Foot
  }
}
</script>
<style lang="scss">

</style>
