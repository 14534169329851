import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    companyName:'',
    record_num:'',
    menuIndex:0
  },
  getters: {
  },
  mutations: {
    setCompany(state, payload) {
      state.companyName = payload.companyName;
      state.record_num = payload.record_num
    },
    setMenuIndex(state, payload){
      state.menuIndex = payload
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState({storage:window.localStorage})]
})
